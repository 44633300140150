<template>
  <!-- eslint-disable -->
  <!--begin::Header-->
  <div id="kt_header" class="header align-items-stretch">
    <!--begin::Container-->
    <div class="container-xxl d-flex align-items-center">
      <!--begin::Heaeder menu toggle-->
      <div class="d-flex align-items-center d-lg-none ms-n2 me-3" title="Show aside menu">
        <div class="btn btn-icon btn-custom w-30px h-30px w-md-40px h-md-40px" id="kt_header_menu_mobile_toggle">
          <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
          <span class="svg-icon svg-icon-2x">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
              <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black" />
            </svg>
          </span>
          <!--end::Svg Icon-->
        </div>
      </div>
      <!--end::Heaeder menu toggle-->
      <!--begin::Header Logo-->
      <div class="header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0">
        <router-link to="/">
          RWDZ API
        </router-link>
      </div>
      <!--end::Header Logo-->
      <!--begin::Wrapper-->
      <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
        <!--begin::Navbar-->
        <div class="d-flex align-items-stretch" id="kt_header_nav">
          <!--begin::Menu wrapper-->
          <div class="header-menu align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_header_menu_mobile_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">
            <!--begin::Menu-->
            <div class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch" id="#kt_header_menu" data-kt-menu="true">
              <div class="menu-item me-lg-1">
                <span class="menu-link py-3">
                  <router-link to="/" class="menu-title">Specyfikacja</router-link>
                  <span class="menu-arrow d-lg-none"></span>
                </span>
              </div>
              <div class="menu-item me-lg-1">
                <span class="menu-link py-3">
                  <router-link v-if="profile && profile.is_admin" to="/admin" class="menu-title">Strefa administratora</router-link>
                  <router-link v-else to="/dashboard" class="menu-title">Strefa developera</router-link>
                  <span class="menu-arrow d-lg-none"></span>
                </span>
              </div>
            </div>
            <!--end::Menu-->
          </div>
          <!--end::Menu wrapper-->
        </div>
      </div>
      <div v-if="profile && profile.is_admin" class="m-5">
        <label class="form-check form-switch form-check-custom form-check-solid">
          <input @click="toggleAdminMode" class="form-check-input w-30px h-20px" type="checkbox" :checked="adminMode">
          <span v-if="adminMode" class="form-check-label text-white fs-6">Widok administratora</span>
          <span v-else class="form-check-label text-white fs-6">Widok użytkownika</span>
        </label>
      </div>
      <div v-if="profile" class="d-flex align-items-center ms-1 ms-lg-3">
        <div class="user menu">
          <i class="fas fa-user"></i>
        </div>
        <div class="user shortname">
            {{ shortName }}
        </div>
        <button type="button" v-if="profile.is_admin == false" class="logout btn btn-primary" @click="$parent.$refs.api.logout()">
            Wyloguj
        </button>
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Header-->
</template>

<script>
export default {
  name: 'TopBar',
  inject: ['$profile'],
  data() {
    return {
      organisation: false,
      adminMode: false
    }
  },
  methods: {
    toggleAdminMode: function() {
      this.adminMode = !this.adminMode;
      this.$bus.emit('updateAdminMode', this.adminMode);
    }
  },
  computed: {
    profile() {
      return this.$profile();
    },
    shortName() {
      if (!this.profile)
      {
        return '';
      }
      return this.profile.first_name.substr(0,1).toUpperCase() + this.profile.last_name.substr(0,1).toUpperCase();
    }
  },
  watch: {
    profile: function (profile) {
      if (profile.is_admin)
      {
          this.adminMode = true;
      }
    }
  }
};
</script>

<style>
ul.roles {
  list-style-type: none;
  padding: 0;
}
ul.roles li {
  padding: 0;
  padding-bottom: 8px;
}
</style>
