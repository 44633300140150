<template>
<div class="page bg-white container">
  <div class="swagger" id="swagger"></div>
</div>
<div class="d-flex flex-center flex-column-auto p-10">
  <!--begin::Links-->
  <div class="d-flex align-items-center fw-bold fs-6">
    <a href="https://www.gunb.gov.pl" class="text-muted text-hover-primary px-2">Główny Urząd Nadzoru Budowlanego</a>
  </div>
  <!--end::Links-->
</div>
</template>

<script>
import SwaggerUI from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';

export default {
    name: "Swagger",
    mounted() {
      var spec = '';
      if (location.host == 'dev.wyszukiwarka.gunb.gov.pl')
      {
        spec = require('../assets/openapi.json');
      }
      else
      {
        spec = require('../assets/openapi-test.json');
      }
      this.$root.fullview = true;
      SwaggerUI({
          spec: spec,
          dom_id: '#swagger'
      })
    },
    deactivated() {
        this.$root.fullView = false;
    }
}
</script>

<style>
</style>
